import Script from 'next/script';
import { ReactElement } from 'react';

const config = {
  id: '00DHs000001vAS0',
  name: 'ChatParceiro',
  url: 'https://connect-java-8396.my.site.com/ESWChatParceiro1731070029939',
  src: 'https://connect-java-8396.my.salesforce-scrt.com'
};

export const RcVirtual = (): ReactElement => (
  <>
    <Script
      id="embedded-messaging-init"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
            function initEmbeddedMessaging() {
              try {
                embeddedservice_bootstrap.settings.language = 'pt_BR';
                embeddedservice_bootstrap.init(
                  '${config.id}',
                  '${config.name}',
                  '${config.url}',
                  {
                    scrt2URL: '${config.src}'
                  }
                );
              } catch (err) {
                console.error('Error on load virtualRc Embedded Messaging: ', err);
              }
            }
          `
      }}
    />

    <Script
      id="embedded-messaging-bootstrap"
      src="https://connect-java-8396.my.site.com/ESWChatParceiro1731070029939/assets/js/bootstrap.min.js"
      strategy="lazyOnload"
      onLoad={() => {
        if (typeof (window as any).initEmbeddedMessaging === 'function') {
          (window as any).initEmbeddedMessaging();
        }
      }}
    />
  </>
);
